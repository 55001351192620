<template>
  <AuthLayout>
    <v-card class="auth-card cust-index">
      <v-fade-transition mode="in-out">
        <v-col class="py-0 mb-8">
          <p
            :class="[
              'text-center',
              $vuetify.breakpoint.xs ? 'text-h6' : 'text-h4',
            ]"
            class="font-weight-bold mb-4"
          >
            {{ $t("auth.register_title") }}
          </p>
        </v-col>
      </v-fade-transition>

      <v-form @submit.prevent="onRegister">
        <v-row align="start">
          <v-col cols="6" class="py-0 mb-5">
            <v-text-field
              :maxLength="40"
              outlined
              :label="$t('form.name')"
              dense
              height="48px"
              hide-details="auto"
              color="primary"
              v-model="user.name"
              class="auth-input"
              :error-messages="nicknameErrors"
            />
          </v-col>
          <v-col cols="6" class="py-0 mb-5">
            <v-text-field
              :label="$t('form.phone_number')"
              dense
              outlined
              height="48px"
              color="primary"
              hide-details="auto"
              class="auth-input"
              prefix="+38"
              v-on:keypress="validationForPhoneNumber($event)"
              @paste="validationForPhoneNumber($event)"
              v-model="user.phone"
              :maxLength="10"
              :error-messages="phoneErrors"
            />
          </v-col>
          <v-col cols="12" class="py-0 mb-5">
            <v-text-field
              :maxLength="30"
              outlined
              :label="$t('form.email')"
              dense
              height="48px"
              color="primary"
              hide-details="auto"
              v-model="user.email"
              class="auth-input"
              :error-messages="emailErrors"
              @input="validationForEmail($event)"
            />
          </v-col>
          <v-col cols="12" class="py-0 mb-5">
            <v-text-field
              outlined
              height="48px"
              color="primary"
              hide-details="auto"
              class="auth-input"
              :label="$t('form.password')"
              dense
              v-model="user.password"
              :type="newPasswordVisible ? 'text' : 'password'"
              :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="newPasswordVisible = !newPasswordVisible"
              :error-messages="newPassErrors"
              v-on:keypress="validationForPassword($event)"
              @input="validationForPassword($event)"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              height="48px"
              color="primary"
              hide-details="auto"
              class="auth-input"
              :label="$t('profile.сonfirm_pass')"
              dense
              v-model="user.repeatPassword"
              :type="repeatPasswordVisible ? 'text' : 'password'"
              :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="repeatPasswordVisible = !repeatPasswordVisible"
              :error-messages="newPassRepeatErrors"
              v-on:keypress="validationForPassword($event)"
              @input="validationForPassword($event)"
            />
          </v-col>
          <v-col class="text-center py-0 mb-4" cols="12">
            <v-btn
              class="text-transform-none btn-blue-bg"
              large
              type="submit"
              block
              style="font-size: 16px; font-weight: 500"
            >
              {{ $t("auth.btn_register") }}
            </v-btn>
          </v-col>
          <v-col
            class="text-center py-0 d-flex align-center justify-center"
            no-gutters
          >
            <p class="mb-0 text-body-2">{{ $t("auth.already_registered") }}</p>
            <v-btn
              color="primary"
              text
              @click="redirectToLogin"
              class="font-weight-medium text-transform-none px-2"
            >
              {{ $t("auth.link_to_register") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/auth/authLayout/AuthLayout.vue";
import passwordMask from "@/validators/passwordMask";
import { required, email, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {
  validationForEmail,
  validationForPassword,
  validationForPhoneNumber,
} from "@/mixins/helpers";
import * as actionTypes from "@/store/modules/auth/types/actions";
import { mapActions } from "vuex";
import notifications from "@/mixins/notifications";
import phoneMask from "@/validators/phoneMask";

export default {
  name: "RegisterComponent",
  mixins: [validationMixin, notifications],
  data: () => ({
    passwordVisible: false,
    user: {
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
      phone: "",
    },
    newPasswordVisible: false,
    repeatPasswordVisible: false,
  }),
  validations: {
    user: {
      email: { required, email },
      password: { required, passwordMask },
      repeatPassword: {
        required,
        passwordMask,
        sameAsPassword: sameAs("password"),
      },
      name: { required },
      phone: { required, phoneMask },
    },
  },
  components: {
    AuthLayout,
  },
  methods: {
    validationForPhoneNumber,
    ...mapActions("auth", {
      register: actionTypes.REGISTER,
    }),
    validationForEmail,
    validationForPassword,
    async onRegister() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append("name", this.user.name);
          formData.append("email", this.user.email);
          formData.append("phone_number", this.user.phone);
          formData.append("password", this.user.password);
          formData.append("password_confirmation", this.user.repeatPassword);
          await this.register(formData);
          this.setSuccessNotification(this.$t("auth.registration_success"));
          this.redirectToLogin();
          this.clearUser();
          this.$v.$reset();
        } catch (e) {
          this.setErrorNotification(this.getErrorMessage(e?.errors));
        }
      }
    },
    getErrorMessage(errors) {
      const messages = [];

      if (errors?.email) {
        messages.push(this.$t("form.errors.EmailalreadyExists"));
      }

      if (errors?.username) {
        messages.push(this.$t("form.errors.registerUsername"));
      }

      return messages.join(" ");
    },
    clearUser() {
      Object.keys(this.user).forEach((key) => {
        this.user[key] = "";
      });
    },
    redirectToLogin() {
      this.$router.push({ name: "login" }).catch(() => {});
    },
  },
  computed: {
    nicknameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      !this.$v.user.name.required &&
        errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      if (!this.$v.user.phone.required) {
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
        return errors;
      }
      !this.$v.user.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.required &&
        errors.push(this.$t("form.errors.EmailRequired"));
      !this.$v.user.email.email &&
        errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    newPassErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (!this.$v.user.password.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      !this.$v.user.password.required &&
        errors.push(this.$t("form.errors.CurrentPassRequired"));
      return errors;
    },
    newPassRepeatErrors() {
      const errors = [];
      if (!this.$v.user.repeatPassword.$dirty) {
        return errors;
      }
      if (!this.$v.user.repeatPassword.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));
        return errors;
      }
      if (!this.$v.user.repeatPassword.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));
        return errors;
      }
      !this.$v.user.repeatPassword.sameAsPassword &&
        errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
  },
};
</script>
