<template>
    <RegisterComponent />
</template>


<script>

import RegisterComponent from "@/components/auth/RegisterComponent.vue";

export default {
  name: "RegisterView",
  components: {
    RegisterComponent,
  },
};
</script>